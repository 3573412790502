import { currentDomain } from '../utils/session';

const internalSetCookie = (cname, cvalue, exdays = 10) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    let cookieString = cname + "=" + cvalue + ";" + expires + ";path=/;";
    cookieString += "domain=" + currentDomain + ";";
    document.cookie = cookieString;
};

const internalGetCookie = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

const internalRemoveCookie = (cname) => {
    const d = new Date();
    d.setTime(d.getTime() - (24 * 60 * 60 * 1000)); // Set the time to one day in the past
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=;" + expires + ";";
};

export {
    internalSetCookie,
    internalGetCookie,
    internalRemoveCookie
};
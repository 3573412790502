import {internalGetCookie, internalRemoveCookie, internalSetCookie} from "./cookies";

const validKeys = [
	'affiliateId',
	'sourceId',
	'subId',
	'clickId',
	'product',
	'var1',
	'var2',
	'var3',
	'var4',
	'var5',
	'offer',
];

export const AffiliateProduct = Object.freeze({
	Casino: 1,
	Sport: 2,
});

/**
 * Be careful: enum under is case-sensitive
 */
export const OFFER_TYPE = Object.freeze({
	SPOFF1: 'spoff1',
	SPOFF2: 'spoff2',
	SPOFF3: 'spoff3',
	SPOFF5: 'spoff5',
	CA500: 'ca500',
	CAOFF2: 'caoff2',
	CAOFF3: 'caoff3',
	CAOFF4: 'caoff4',
	CAOFF5: 'caoff5',
	CAOFFN500: 'caoffn500',
});

export const trackingQuery = (params) => {
	const affiliateIdKey = validKeys[0];
	if (params[affiliateIdKey] || params[affiliateIdKey.toLowerCase()]) {
		removeTrackingParams();
	}
	Object.keys(params).forEach(key => {
		let valueKey = params[key];
		if(!validKeys.includes(key)) {
			const findKey = validKeys.find(validKey => validKey.toLowerCase() === key.toLowerCase());
			valueKey = key === "product" ? params[key].toString() : valueKey;
			key = findKey;
		}
		if(validKeys.includes(key)) {
			const value = key === "product" ? valueKey.toString() : valueKey;
			setValueToBrowser(key, value);
		}
	});
};

export const setValueToBrowser = (key, value) => {
	internalSetCookie(key, value);
	localStorage.setItem(key, value);
	sessionStorage.setItem(key, value);
};

export const removeValueFromBrowser = (key) => {
	internalRemoveCookie(key)
	localStorage.removeItem(key);
	sessionStorage.removeItem(key);
};

export const removeTrackingParams = () => {
	validKeys.forEach((key) => removeValueFromBrowser(key));
	removeValueFromBrowser('referralCode');
};

export const getValueFromBrowser = (key) => {
	return internalGetCookie(key) || localStorage.getItem(key) || sessionStorage.getItem(key);
};

export const getTrackingValues = (couponCode = '') => {
	return {
		affiliateId: getValueFromBrowser('affiliateId') || '',
		sourceId: getValueFromBrowser('sourceId') || '',
		subId: getValueFromBrowser('subId') || '',
		couponCode,
		clickId: getValueFromBrowser('clickId') || getValueFromBrowser('rtkclickid-store') || '',
		product: +getValueFromBrowser('product') || AffiliateProduct.Casino,
		fullUrl: getValueFromBrowser('fullUrl') || '',
		var1: getValueFromBrowser('var1') || '',
		var2: getValueFromBrowser('var2') || '',
		var3: getValueFromBrowser('var3') || '',
		var4: getValueFromBrowser('var4') || '',
		var5: getValueFromBrowser('var5') || '',
		offer: getValueFromBrowser('offer') || '',
	};
};

export const setSignUpBanner = (signUpPopup) => {
	let userOffer = getValueFromBrowser('offer') || '';
	userOffer = userOffer.toLowerCase();
	const userProduct =
		+getValueFromBrowser('product') ||
		1;
	const banner = signUpPopup.querySelector('.popup__banner');
	let bannerSrc = '';
	if (userProduct === AffiliateProduct.Sport) {
		switch (userOffer) {
			case OFFER_TYPE.SPOFF1:
			case OFFER_TYPE.SPOFF2:
			case OFFER_TYPE.SPOFF3:
			case OFFER_TYPE.SPOFF5:
				bannerSrc = `./assets/images/sport-${userOffer}.webp`;
				break;
			default:
				bannerSrc = './assets/images/sport-default.webp';
				break;
		}
	} else {
		switch (userOffer) {
			case OFFER_TYPE.CA500:
			case OFFER_TYPE.CAOFF2:
			case OFFER_TYPE.CAOFF3:
			case OFFER_TYPE.CAOFF5:
			case OFFER_TYPE.CAOFFN500:
				bannerSrc = `./assets/images/casino-${userOffer}.webp`;
				break;
			default:
				bannerSrc = './assets/images/casino-default.webp';
				break;
		}
	}
	banner.src = bannerSrc;
};